import "./single.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useState, useEffect } from "react";
import { doc, getDoc, setDoc, serverTimestamp } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL, deleteObject } from "firebase/storage";
import { db, storage } from "../../firebase";
import { Objects } from "../../formSource";
import { useNavigate, useParams } from "react-router-dom";
import { FormControl, InputLabel, Input, Select, MenuItem, FormControlLabel, Checkbox } from "@mui/material";

const Single = ({ dataType }) => {
  const inputs = Objects[dataType].inputs;
  const title = Objects[dataType].labels.editTitle;
  const [data, setData] = useState({});
  const [file, setFile] = useState(null);
  const { docId } = useParams();
  const navigate = useNavigate();
  const handleInput = (e) => {
    const id = e.target.id;
    const value = e.target.value;
    setData({...data, [id]:value});
  }

  const handleCheckChange = (e, id) => {
    const value = e.target.checked;
    setData({...data, [id]:value});
  }

  const handleSelect = (e, id) => {
    const value = e.target.value;
    setData({...data, [id]:value});
  }
  
  const docRef = doc(db, dataType, docId);

  const fetchData = async() => {
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setData(docSnap.data())
    } else {
      // docSnap.data() will be undefined in this case
      console.log("No such document!");
    }
  }


  useEffect(() => {
    fetchData();
  }, []);


  const handleFile = (e) => {
    const file = e.target.files[0];
    setFile(file);
  }

  const handleDeleteFile = async () => {
    try {
      // Extract the file path from the URL
      const fileUrl = data.fileUrl;
      const fileRef = ref(storage, fileUrl);
      await deleteObject(fileRef);
      
      // Update local state and database
      const newData = {...data};
      delete newData.fileUrl;
      setData(newData);
      await setDoc(docRef, {...newData, time_stamp: serverTimestamp()});
    } catch (err) {
      console.log(err);
    }
  }

  const handleSave = async(e) => {
    e.preventDefault();
    try {
      let fileUrl = data.fileUrl || "";
      if (file) {
        const fileName = new Date().getTime() + file.name;
        const storageRef = ref(storage, `orders/${fileName}`);
        const uploadTask = await uploadBytesResumable(storageRef, file);
        fileUrl = await getDownloadURL(uploadTask.ref);
      }
      
      const res = await setDoc(docRef, {
        ...data,
        fileUrl: fileUrl,
        time_stamp: serverTimestamp()
      });
      navigate(-1);
    } catch (err) {
      console.log(err);
    }
  }

  // const useStyles = makeStyles({
  //   root: {
  //     width: 350
  //   }
  // });


  return (
    <div className="single">
      <Sidebar />
      <div className="singleContainer">
        <Navbar />
        <div className="top">
          <h1>{title}&nbsp;{dataType === 'orders' ? data.order_code : ""}</h1>
        </div>
        <div className="bottom">
          <div className="right">
          <form onSubmit={handleSave}>
              {inputs.map((input) => (
                <div className="formInput" key={input.id}>
                  <FormControl fullWidth>
                    {((input.type !== "checkbox" && input.type !== "select" && input.type !== "file") || input.id === "client") && 
                    <>
                      <InputLabel shrink={true}>{input.label}</InputLabel>
                      <Input
                        label={input.label}
                        id={input.id}
                        type={input.type}
                        variant="outlined"
                        placeholder={input.placeholder}
                        readOnly={input.id === "client"}
                        disabled={input.id === "client"}
                        value={data[input.id] ? (input.id === "client" ? data[input.id].name : data[input.id]) : ""}
                        onChange={handleInput} 
                        multiline={input.multiline}
                        />
                      </>
                    }

                    {input.type === "file" && 
                      <>
                        <InputLabel shrink={true}>{input.label}</InputLabel>
                        <Input
                          type="file"
                          onChange={handleFile}
                        />
                        {data.fileUrl && (
                          <div style={{marginTop: '10px', display: 'flex', alignItems: 'center', gap: '10px'}}>
                            <a href={data.fileUrl} target="_blank" rel="noopener noreferrer">
                              Voir le fichier actuel
                            </a>
                            <button 
                              type="button" 
                              onClick={handleDeleteFile}
                              style={{
                                padding: '5px 10px',
                                backgroundColor: '#dc3545',
                                color: 'white',
                                border: 'none',
                                borderRadius: '4px',
                                cursor: 'pointer'
                              }}
                            >
                              Supprimer
                            </button>
                          </div>
                        )}
                      </>
                    }
                    {input.type === "checkbox" && 
                      <FormControlLabel
                        control={
                          <Checkbox id={input.id} checked={data[input.id] ?? false} name={input.id} onChange={e => handleCheckChange(e, input.id)} />
                        }
                        label={input.label}
                      />
                    }
                    
                    {input.type === "select" && input.id !== "client" &&
                      <>
                        <InputLabel>{input.label}</InputLabel>
                        <Select
                          id={input.id}
                          value={data[input.id] ?? ""}
                          label={input.label}
                          name={input.id}
                          onChange={e => handleSelect(e, input.id)}
                        >
                          {input.options.map((option, index) => 
                            <MenuItem value={option} key={index}>{option}</MenuItem>)
                          }
                        </Select>
                      </>
                    }
                  </FormControl>
                </div>
              ))}
              <button type="submit">Enregistrer</button>
            </form>

          </div>
        </div>
      </div>
    </div>
  );
};

export default Single;
